import { useState } from "react";
import Spinner from "./Spinner";
import { sendDeleteRequest } from "../Connection";
import DeleteError from "./DeleteError";
import "../css/deletePage.css";
import DeleteModal from "./DeleteModal";

function DeleteAcccountPageForm() {
  const [enteredMobile, setEnteredMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [showModal, setShowmodal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onInputChange = (e) => {
    const { value } = e.target;
    setEnteredMobile(value);
    // console.log(enteredMobile);
  };

  const closeModal = () => {
    setShowmodal(false);
  };
  const closeError = () => {
    setShowError(false);
  };

  const submitMobile = () => {
    if (/^\d{10}$/.test(enteredMobile)) {
      setLoading(true);
      sendDeleteRequest(enteredMobile, (response) => {
        if (response === true) {
            setLoading(false);
            setShowmodal(true);
        }
        else{
          setLoading(false);
          setShowError(true);
        }
      });
      setEnteredMobile('');
      setMobileError("");
    } else {
      setMobileError("Please enter valid mobile number");
    }
  };
  

  return (
    <>
    {showError && <DeleteError closeError={closeError} />}
    {showModal && <DeleteModal closeModal={closeModal} />}
      {loading && (
        <div className="center-screen">
          <Spinner />
        </div>
      )}
      {!loading && (
        <div className="delete-wrapper">
          <div className="delete-box">
            <div className="delete_input">
              <label htmlFor="mobile">Enter ARYO registered Mobile Number</label>
              <input
                type="tel"
                name="mobile"
                id="mobile"
                onChange={onInputChange}
                maxLength={10}
                value={enteredMobile}
                placeholder="Mobile Number"
              />
              {mobileError && <p className="delete-error">{mobileError}</p>}
            </div>

            <div className="delete_button">
              <button onClick={submitMobile}>Submit</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DeleteAcccountPageForm;
