import React from "react";
import { Link } from "react-router-dom";
import badge from "../images/googleplay_badge.png";
import aryoVideo from "../Videos/newgif22.gif";
import halfstar from "../images/halfstar.png";
import star from "../images/golden_star.png";
import rocket from "../images/rocket-joypixels2.gif";
import qr from "../images/aryoQr.png";

import "../css/banner.css";

function Banner() {
  return (
    <>
      <div className="banner-container">
        <div className="banner">
          <img src={aryoVideo} alt="aryovideo" id="video" />

          <Link
            to="https://play.google.com/store/apps/details?id=com.aryo.aryoleads&referrer=utm_source%3Daryo-website%26utm_term%3Dwebsite%26utm_campaign%3Dwebsite"
            className="link-banner-mobile"
            target="_blank"
          >
            <img src={badge} alt="playstore" id="banner-badge" />
          </Link>

          <div className="star_rating_mobile">
            <div className="star_mobile">
              <img src={star} alt="" />
              <img src={star} alt="" />
              <img src={star} alt="" />
              <img src={star} alt="" />
              <img src={halfstar} alt="" />
              <p id="rating_ss">4.3</p>
            </div>
          </div>
        </div>
        <div className="write-up">
          <p id="banner-bharat">
            Join India's Growth Story{" "}
            <span>
              <img src={rocket} alt="grow" className="rocket" />
            </span>
          </p>
          <p id="banner-writeup">
            <span id="sell">
              <span id="sell2"> Sell Financial Products</span>{" "}
              <span id="andlogo">&</span> <br />
              Earn Handsome Income
              <br />
              <span id="write-up_suffix">
                with <span>Zero Investment</span>{" "}
              </span>
            </span>
            <br />
          </p>
          <Link
            to="https://play.google.com/store/apps/details?id=com.aryo.aryoleads&referrer=utm_source%3Daryo-website%26utm_term%3Dwebsite%26utm_campaign%3Dwebsite"
            className="linkPath-badge"
            target="_blank"
          >
            <img src={badge} alt="" id="banner-badge" />
          </Link>

          <div className="star-rating">
            <div className="star">
              {" "}
              <img src={star} alt="" />
              <img src={star} alt="" />
              <img src={star} alt="" />
              <img src={star} alt="" />
              <img src={halfstar} alt="" />
              <p id="rating">4.3</p>
              <br />
            </div>
          </div>
        </div>
        <div className="qrBox">
          <img src={qr} alt="" />
          <p>Download <br /> ARYO</p>
        </div>
      </div>
    </>
  );
}

export default Banner;
